import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FlowService, WorkPlatformService } from '@/sevices';
import { isPC, isPhone } from '@/common/utils';
import { service } from '@cloudpivot-hermes/nail-free-login';
import { CTable } from '@cloudpivot-hermes/common-components';
const Global = namespace('Global');
let FlowInstance = class FlowInstance extends Vue {
    constructor() {
        super(...arguments);
        this.isShowFilter = false;
        this.isShowFlowException = false;
        this.flowId = '';
        this.searchParams = {};
        this.statusMap = {
            NO_RECOVERED: '未修复',
            RECOVERED: '已修复',
        };
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                fixed: 'left',
                slots: { title: 'indexTitle', name: '序号' },
            },
            {
                width: 200,
                dataIndex: 'workflowInstanceName',
                key: 'workflowInstanceName',
                ellipsis: true,
                slots: { title: 'workflowInstanceNameTitle', name: '流程实例名称' },
            },
            {
                width: 200,
                dataIndex: 'workflowName',
                key: 'workflowName',
                ellipsis: true,
                slots: { title: 'workflowNameTitle', name: '流程模板' },
            },
            {
                width: 220,
                dataIndex: 'createdTime',
                key: 'createdTime',
                ellipsis: true,
                slots: { title: 'createdTimeTitle', name: '创建时间' },
            },
            {
                dataIndex: 'createrName',
                key: 'createrName',
                ellipsis: true,
                slots: { title: 'createrNameTitle', name: '发起人' },
            },
            {
                width: 180,
                dataIndex: 'status',
                key: 'status',
                ellipsis: true,
                slots: { title: 'statusTitle', name: '修复状态' },
            },
            {
                width: 220,
                dataIndex: 'fixedTime',
                key: 'fixedTime',
                ellipsis: true,
                slots: { title: 'fixedTimeTitle', name: '修复时间' },
            },
            {
                width: 150,
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                fixed: 'right',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
    }
    showFilter() {
        this.isShowFilter = true;
    }
    closeFilter() {
        this.isShowFilter = false;
    }
    get IsDingTalk() {
        return sessionStorage.IS_DINGTALK === 'true';
    }
    get isFreeLoginPlatform() {
        return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
    }
    get ISWECHAT() {
        return sessionStorage.IS_WECHAT === 'true';
    }
    async handler(item) {
        const params = {
            appCode: item.appCode,
            appVersion: item.appVersion,
        };
        const res = await WorkPlatformService.getAppUrl(params);
        if (res.success) {
            const pageJsonRes = await FlowService.getPageJson({
                appCode: item.appCode,
                workflowInstanceId: item.workflowInstanceId,
            });
            if (!pageJsonRes.success)
                return;
            const winOpen = !this.isFreeLoginPlatform && window.open('', '_blank');
            const workItemData = pageJsonRes.data || {};
            const pageArr = workItemData.boundPages || [];
            let pageJson = {};
            for (const page of pageArr) {
                // pageType:ALL,PC,MOBILE
                if (page.pageType !== 'ALL') {
                    if (isPC() && page.pageType === 'PC') {
                        pageJson = page;
                        break;
                    }
                    else if (isPhone() && page.pageType === 'MOBILE') {
                        pageJson = page;
                        break;
                    }
                    else {
                        pageJson = page;
                    }
                }
                else {
                    pageJson = page;
                    break;
                }
            }
            const query = {
                appCode: '',
                groupCode: '',
                pageGroupCode: '',
                pageCode: '',
                workItemId: workItemData.workItemId,
                workflowInstanceId: workItemData.workflowInstanceId,
                appVersion: item.appVersion,
                workflowCode: item.workflowCode,
                ...pageJson,
            };
            const str = Object.entries(query)
                .map((queryArr) => {
                return queryArr.join('=');
            })
                .join('&');
            let url = `${res.data}?${str}`;
            if (process.env.NODE_ENV !== 'production') {
                url = `http://localhost${res.data.substring(res.data.indexOf('/api/launcher/'))}?${str}`;
            }
            try {
                if (this.ISWECHAT && isPhone()) {
                    const token = localStorage.getItem('token');
                    const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                    window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                }
                else if ((this.IsDingTalk || this.ISWECHAT)) {
                    const token = localStorage.getItem('token');
                    const userInfo = encodeURIComponent(localStorage.getItem('user') || '');
                    if (this.needOpenBrowser) {
                        service.openLink(`${url}&token=${token}&userInfo=${userInfo}`);
                    }
                    else {
                        window.open(`${url}&token=${token}&userInfo=${userInfo}`);
                    }
                }
                else {
                    if (!winOpen) {
                        throw new Error('浏览器新开弹窗未开启');
                    }
                    setTimeout(() => {
                        winOpen.location = url;
                    }, 100);
                }
            }
            catch (e) {
                Vue.prototype.$errorcatcher.showMessagePopup({
                    title: '错误提示！',
                    message: [e, '!'],
                    displayFooter: false,
                    correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
                });
            }
            // window.location.href = url;
        }
    }
    showExceptionLog(item) {
        this.isShowFlowException = true;
        this.flowId = item.id;
    }
    /**
     * 搜索
     */
    search(searchParams = {}) {
        const params = {
            endTime: searchParams.endTime,
            startTime: searchParams.startTime,
            workflowInstanceName: searchParams.instanceName,
            creater: searchParams.originator || '',
            workflowCode: searchParams.workflowCode,
        };
        this.searchParams = params;
        this.cTable.pagination.current = 1;
        this.isShowFilter = false;
        this.cTable.getTableList();
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const p = {
            ...params,
            ...this.searchParams,
        };
        const res = await FlowService.getExceptionList(p);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                item.status = this.statusMap[item.status];
            });
        }
        return res;
    }
    reload() {
        this.cTable.pagination.current = 1;
        this.isShowFilter = false;
        this.cTable.getTableList();
    }
};
__decorate([
    Ref()
], FlowInstance.prototype, "cTable", void 0);
__decorate([
    Ref()
], FlowInstance.prototype, "filterFlowInstance", void 0);
__decorate([
    Global.State('needOpenBrowser')
], FlowInstance.prototype, "needOpenBrowser", void 0);
FlowInstance = __decorate([
    Component({
        name: 'FlowInstance',
        components: {
            CTable,
            FilterDrawer: () => import('@/components/flow-manage/filter-drawer'),
            FlowExceptionDetail: () => import('@/components/flow-manage/flow-exception-detail'),
            FilterFlowInstance: () => import('@/components/flow-manage/filter-drawer/filter-flowInstance.vue'),
        },
    })
], FlowInstance);
export default FlowInstance;
